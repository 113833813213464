import React, { useState, useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import Container from "~/components/ui/container"
import Accordion from "~/components/ui/accordion"
import useCareerOpportunities from "~/hooks/cms/useCareerOpportunities"

const HeroSection: React.FC = () => {
  const careers = useCareerOpportunities()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "careers_bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const memoizedCareers = useMemo(() => {
    return careers.map(obj => {
      return obj.node.data
    })
  }, [])

  return (
    <section className={styles.root}>
      <div className={styles.background}>
        <div className={styles.background__img}>
          <GatsbyImage
            alt="Worker smiling"
            image={data.file.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <Container>
          <h1
            className={classNames(
              "headline--decorated",
              styles.info__headline__mobile
            )}
          >
            Pave the way <br /> <span>for the future</span>
          </h1>
        </Container>
      </div>
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.info}>
            <h1
              className={classNames(
                "headline--decorated",
                styles.info__headline__desktop
              )}
            >
              Pave the way <br /> <span>for the future</span>
            </h1>
            <p className={styles.info__paragraph}>
              By working with the URUS Group and our companies, you are
              connected to the heart of the global dairy and beef industries.
              Our companies employ 2,000 highly ambitious, hard-working people
              globally that share a passion to support farmers and their goals
              for future generations.
            </p>
            <p className={styles.info__paragraph}>
              Our relentless focus on excellence and business growth has created
              career opportunities in high-impact positions. We invite you to
              apply for the featured roles below and to check our company
              websites for more opportunities to start your career with URUS!
            </p>
          </div>
        </Container>
        <div className={styles.main}>
          <p className={styles.main__paragraph}>Come work with us</p>
          <h3 className={styles.main__headline}>Leadership Opportunities</h3>
          <Accordion items={memoizedCareers} />
        </div>
      </div>
    </section>
  )
}

export default HeroSection
