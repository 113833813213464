import * as React from "react"
import classNames from "classnames"

import Expand from "~/images/expand.svg"
import Button from "~/components/ui/button"

import * as styles from "./index.module.scss"

interface Props {
  item: any
  setOpenedItem: (id: number) => void
  isOpen: boolean
  id: number
}

const AccordionItem: React.FC<Props> = ({
  item,
  setOpenedItem,
  isOpen,
  id,
}) => {
  const ref = React.useRef(null)
  const handleClick = () => {
    if (!isOpen) {
      setOpenedItem(id)
    } else {
      setOpenedItem(null)
    }
  }
  return (
    <div
      className={classNames("headline--decorated", styles.root, {
        [styles.root__open]: isOpen,
      })}
    >
      <button
        aria-expanded={isOpen ? "true" : "false"}
        aria-controls={`sect${id}`}
        id={`accordion${id}id`}
        className={classNames(styles.button)}
        onClick={handleClick}
      >
        <h3 className="h-sm">{item.title.text}</h3>
        <Expand
          className={classNames(styles.icon, {
            [styles.icon__opened]: isOpen,
          })}
        />
      </button>
      <div
        id={`sect${id}`}
        aria-labelledby={`accordion${id}id`}
        ref={ref}
        className={classNames(styles.text, {
          [styles.text__open]: isOpen,
        })}
        style={{
          opacity: isOpen ? 1 : 0,
          maxHeight: isOpen ? `${ref.current.scrollHeight}px` : 0,
        }}
      >
        <p>{item.description.text}</p>
        {item.link.url && (
          <div className={styles.link}>
            <Button to={item.link.url} isLink={true}>
              Apply
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccordionItem
