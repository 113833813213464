import { graphql, useStaticQuery } from "gatsby"

const useCareerOpportunities = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicCareerOpportunity {
        edges {
          node {
            data {
              description {
                text
              }
              link {
                url
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicCareerOpportunity.edges
}

export default useCareerOpportunities
