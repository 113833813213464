import React, { useState } from "react"
import AccordionItem from "./item"

interface Props {
  items: any[]
}

const Accordion: React.FC<Props> = ({ items }) => {
  const [openedItem, setOpenedItem] = useState(null)
  return (
    <>
      {items.map((item, i) => (
        <AccordionItem
          key={i}
          item={item}
          setOpenedItem={setOpenedItem}
          isOpen={i === openedItem}
          id={i}
        />
      ))}
    </>
  )
}

export default Accordion
