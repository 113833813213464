// extracted by mini-css-extract-plugin
export var background = "index-module--background--9d287";
export var background__img = "index-module--background__img--9d6a8";
export var info = "index-module--info--bad37";
export var info__headline__desktop = "index-module--info__headline__desktop--f1ee9";
export var info__headline__mobile = "index-module--info__headline__mobile--71ec9";
export var info__paragraph = "index-module--info__paragraph--b1526";
export var main = "index-module--main--ea0ec";
export var main__headline = "index-module--main__headline--1b25b";
export var main__paragraph = "index-module--main__paragraph--0342a";
export var root = "index-module--root--72fd9";
export var wrapper = "index-module--wrapper--957ef";